import Layout from "./layout";
import Home from "./views";

function App() {
  return (
    <div className="font-gluten tracking-widest">
      <Layout>
        <Home />
      </Layout>
    </div>
  );
}

export default App;
