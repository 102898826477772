import { useEffect } from "react";
import ReactDom from "react-dom";
import "src/assets/styles/modal.css";
import { motion } from "framer-motion";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { useStateValue } from "src/context/StateProvider";
import GetListedForm from "src/components/GetListedForm";

export default function GetListedModal({ modalIsOpen, setModalIsOpen }) {
  const [{ connectedWalletAddress }] = useStateValue();

  useEffect(() => {
    if (connectedWalletAddress) {
      setModalIsOpen(false);
    }
  }, [connectedWalletAddress, setModalIsOpen]);

  if (!modalIsOpen) return null;

  return ReactDom.createPortal(
    <div className="modalOverlay">
      <motion.div
        className="bg-secondary rounded-lg shadow-xl "
        initial={{ y: -150 }}
        animate={{ y: 0 }}
        transition={{
          type: "spring",
          stiffness: 260,
          damping: 20,
          duration: 0.5,
        }}
      >
        <div className="flex relative justify-between items-center mb-4 ">
          <div className="flex flex-col items-center mt-6">
            <h2 className="text-primary font-bold md:text-lg">Get Listed!</h2>
            <div className="mt-4 px-4 pt-2 pb-2">
              <GetListedForm setModalIsOpen={setModalIsOpen} />
            </div>
          </div>

          <div
            className="cursor-pointer absolute right-4 top-4 text-gray-600 hover:text-red-500"
            onClick={() => setModalIsOpen(false)}
          >
            <AiOutlineCloseCircle size={25} />
          </div>
        </div>
      </motion.div>
    </div>,
    document.getElementById("portal")
  );
}
