import React, { useState, useEffect } from "react";
import { ImFolderDownload } from "react-icons/im";
import { SiDiscord } from 'react-icons/si';
import { MdLibraryAddCheck, MdAddShoppingCart } from "react-icons/md";
import { Watch } from "react-loader-spinner";
import { useStateValue } from "src/context/StateProvider";
import { IWhitelist } from "src/interfaces/projects";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css";
import BuyWhitelistModal  from "./BuyWhitelistModal";


export default function ProjectCard({
  id,
  name,
  cost,
  userFriendlyCost,
  imgURL,
  discordURL,
  size,
  maxSize,
  addresses,
  discordIds,
  project
}: IWhitelist) {
  const [{ connectedWalletAddress, currentProjectTokenSymbol }] = useStateValue();
  const [modalIsOpen, setModalIsOpen] = useState<boolean>(false);

  const handlePurchase = () => {
    if (connectedWalletAddress === project.owner) {
      return;
    }
    setModalIsOpen(true);
  };

  function download(filename: string, text: string) {
    var element = document.createElement('a');
    element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(text));
    element.setAttribute('download', filename);
  
    element.style.display = 'none';
    document.body.appendChild(element);
  
    element.click();
  
    document.body.removeChild(element);
  }

  const handleDownload = async () => {
    const data = addresses.map((address, index) => `${address},${discordIds[index]}`);
    download(`${project.name}-${name}.csv`, ["Address,DiscordId", data.join("\n")].join("\n"));
  }
  

  return (
    <>
      <div className="flex relative hover:shadow-xl">
        <img src={imgURL} alt="" className="w-[214px] h-[225px] rounded-2xl" />

        <div className="project-card-title-overlay">
          <h4><a href={discordURL} target="_blank">{name}</a></h4>
          <h4>{userFriendlyCost} {currentProjectTokenSymbol}</h4>
        </div>
        <div className="project-card-count_overlay">{size}/{maxSize}</div>
        {connectedWalletAddress && addresses.length > 0 && connectedWalletAddress === project.owner && (
          <Tippy
            content={
              <>
                <p className="p-4">Download</p>
              </>
            }
            delay={500}
            placement="top"
          >
            <div className="absolute top-4 right-4 bg-primaryTransparent cursor-pointer p-2 rounded-md"
            onClick={handleDownload}>
              <ImFolderDownload className="hover:text-yellow-400 text-white" />
            </div>
          </Tippy>
        )}
        {currentProjectTokenSymbol && connectedWalletAddress && connectedWalletAddress !== project.owner && !addresses.includes(connectedWalletAddress) && (
          <Tippy
            content={
              <>
                <p className="p-4">Purchase</p>
              </>
            }
            delay={500}
            placement="top"
          >
            <div
              className="absolute top-4 right-4 bg-primaryTransparent cursor-pointer p-2 rounded-md"
              onClick={handlePurchase}
            >
              <MdAddShoppingCart className="hover:text-yellow-400  text-white" />
            </div>
          </Tippy>
        )}
        {connectedWalletAddress && connectedWalletAddress !== project.owner && addresses.includes(connectedWalletAddress) && (
          <Tippy
            content={
              <>
                <p className="p-4">Purchased</p>
              </>
            }
            delay={500}
            placement="top"
          >
            <div
              className="absolute top-4 right-4 bg-primaryTransparent"
            >
              <MdLibraryAddCheck className="hover:text-white-400  text-white" />
            </div>
          </Tippy>
        )}
      </div>

      <BuyWhitelistModal
        modalIsOpen={modalIsOpen}
        setModalIsOpen={setModalIsOpen} walletAddress={connectedWalletAddress} project={project}
        whitelistCost={cost} whitelistId={id} whitelistName={name} />
    </>
  );
}
