import React from "react";
import { motion } from "framer-motion";

export default function FadeInLeft({ children, showComponent }) {
  if (!showComponent) {
    return null;
  }
  return (
    <div>
      {" "}
      <motion.div
        initial={{ x: -200 }}
        animate={{ x: 0 }}
        transition={{
          type: "spring",
          stiffness: 100,
          damping: 20,
          duration: 1,
        }}
      >
        {children}
      </motion.div>
    </div>
  );
}
