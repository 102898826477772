import React, { useState, useEffect, useCallback } from "react";
import { create } from 'ipfs-http-client';
import clsx from "clsx";
import wait from "wait";
import "src/assets/styles/modal.css";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useStateValue } from "src/context/StateProvider";
import { addWhitelist, isTransactionMined } from "src/ethereum/contracts";
import { FilePond, registerPlugin } from "react-filepond";
import { handleContractCall, showInfo } from "src/context/helpers";

// Import FilePond styles
import "filepond/dist/filepond.min.css";

// Import the Image EXIF Orientation and Image Preview plugins
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import FilePondPluginImageResize from "filepond-plugin-image-resize";
import FilePondPluginFileEncode from "filepond-plugin-file-encode";
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";
import FilePondPluginImageTransform from "filepond-plugin-image-transform";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";

// Register the plugins
registerPlugin(
  FilePondPluginFileEncode,
  FilePondPluginImageExifOrientation,
  FilePondPluginImagePreview,
  FilePondPluginImageResize,
  FilePondPluginFileValidateType,
  FilePondPluginImageTransform
);

toast.configure();

export default function AddWhitelistForm({ setModalIsOpen }) {
  const [ { selectedProject, currentProjectTokenSymbol }, dispatch] = useStateValue();
  const [submitDisabled, setSubmitDisabled] = useState(true);
  const [whitelistName, setWhitelistName] = useState("");
  const [shortDesc, setShortDesc] = useState("");
  const [discordURL, setDiscordURL] = useState("");
  const [cost, setCost] = useState();
  const [maxSize, setMaxSize] = useState();
  const [image, setImage] = useState(null);
  const [btnText, setBtnText] = useState("Waiting ...");

  const handleWhitelistNameChange = (event) => {
    setWhitelistName(event.target.value);
  }

  const handleShortDescChange = (event) => {
    setShortDesc(event.target.value);
  }

  const handleCostChange = (event) => {
    setCost(event.target.value);
  }

  const handleMaxSizeChange = (event) => {
    setMaxSize(event.target.value);
  }

  const handleDiscordURL = (event) => {
    setDiscordURL(event.target.value);
  }

  const handleSubmit = async () => {
    handleContractCall(addWhitelist(selectedProject, whitelistName, shortDesc, cost, maxSize, image, discordURL));
    setModalIsOpen(false);
  };

  const onAddFile = async (file) => {
    await wait(1000);
    const client = create(process.env.REACT_APP_IPFS_FILE_UPLOAD_URL);
    const added = await client.add(file.file);
    const imageURL = `${process.env.REACT_APP_IPFS_FILE_BASE_URL}/${added.path}`;
    console.log(`uploaded image to ${imageURL}`);
    setImage(imageURL);
  };

  const onRemoveFile = () => {
    setImage(null);
  };

  const checkSubmitStatus = useCallback(() => {
    if (image) {
      setSubmitDisabled(false);
      setBtnText("Upload");
    } else {
      setSubmitDisabled(true);
    }
  }, [image]);

  useEffect(() => {
    checkSubmitStatus();
  }, [image, checkSubmitStatus]);

  return (
    <>
      {/* Page Main Content */}
      <div className="md:bg-pale-purple-bg">
        <div className="container md:grid md:place-items-center md:h-full ">
          {/* White Card */}
          <div className="bg-primary md:p-6 pt-6 rounded-2xl md:shadow-md w-auto md:w-[22rem]">
            {/* Text Inputs */}
            <div>
              <label className="block mb-2 text-primary text-sm font-medium">
                Whitelist Name
              </label>
              <input
                className="block w-full px-4 py-2 text-gray-700 bg-white border rounded-md focus:border-blue-500 focus:outline-none focus:ring"
                onChange={handleWhitelistNameChange}
                type="text"
                placeholder="Whitelist Name"
                name="whitelistName"
                value={whitelistName}
              />
            </div>
            <div>
              <label className="block mb-2 text-primary text-sm font-medium">
                Short Description
              </label>
              <input
                className="block w-full px-4 py-2 text-gray-700 bg-white border rounded-md focus:border-blue-500 focus:outline-none focus:ring"
                onChange={handleShortDescChange}
                type="text"
                placeholder="Short Description"
                name="shortDesc"
                value={shortDesc}
              />
            </div>
            <div>
              <label className="block mb-2 text-primary text-sm font-medium">
                Cost
              </label>
              <input
                className="block w-full px-4 py-2 text-gray-700 bg-white border rounded-md focus:border-blue-500 focus:outline-none focus:ring"
                onChange={handleCostChange}
                type="number"
                placeholder={`cost in ${currentProjectTokenSymbol}`}
                name="cost"
                value={cost}
              />
            </div>
            <div>
              <label className="block mb-2 text-primary text-sm font-medium">
                DiscordURL
              </label>
              <input
                className="block w-full px-4 py-2 text-gray-700 bg-white border rounded-md focus:border-blue-500 focus:outline-none focus:ring"
                onChange={handleDiscordURL}
                type="text"
                placeholder="discordURL"
                name="discordURL"
                value={discordURL}
              />
            </div>
            <div>
              <label className="block mb-2 text-primary text-sm font-medium">
                Maximum Size
              </label>
              <input
                className="block w-full px-4 py-2 text-gray-700 bg-white border rounded-md focus:border-blue-500 focus:outline-none focus:ring"
                onChange={handleMaxSizeChange}
                type="number"
                placeholder="Whitelist's Maximum Size"
                name="maxSize"
                value={maxSize}
              />
            </div>
            <div className="flex flex-col justify-around py-4">
              <FilePond
                files={image ? [image] : []}
                oninitfile={(file) => {
                  onAddFile(file);
                }}
                onremovefile={(errRes, file) => onRemoveFile()}
                onactivatefile={onRemoveFile}
                allowMultiple={false}
                allowRemove={true}
                allowFileTypeValidation={true}
                credits={false}
                allowImageResize={true}
                allowImageTransform={true}
                imageResizeTargetWidth={"20"}
                acceptedFileTypes={["image/png", "image/jpeg"]}
                name="files"
                labelIdle='Drag & Drop an Image or <span class="filepond--label-action">Browse</span>'
              />
            </div>

            {/* Submit Button */}
            <div className=" flex justify-around mt-6 pb-2">
              <button
                type="button"
                onClick={handleSubmit}
                disabled={submitDisabled}
                className={clsx(
                  {
                    "bg-blue-400 items-center gap-2 w-full justify-center flex font-semibold hover:bg-secondary-light hover:shadow-xl text-white rounded-xl pl-6 pr-4 py-2 z-10 cursor-pointer":
                      !submitDisabled,
                  },
                  {
                    "bg-[#cdc4d7] items-center gap-2 w-full justify-center flex font-semibold text-white rounded-xl pl-6 pr-4 py-2 z-10":
                      submitDisabled,
                  }
                )}
              >
                {btnText}
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
