import clsx from "clsx";
import { useState, useEffect } from "react";
import { AiOutlineSearch } from "react-icons/ai";
import { useStateValue } from "src/context/StateProvider";
import { IProject } from "src/interfaces/projects";
import { enabledProjects } from "src/ethereum/contracts";
import GetListedModal from "./GetListedModal";
import { updateProjectSymbol, updateProjectTokenBalance } from "src/context/helpers"; 


export default function Menu() {
  const [
    { showMobileMenu, projectList, 
      selectedProject },
    dispatch,
  ] = useStateValue();
  const [modalIsOpen, setModalIsOpen] = useState<boolean>(false);
  const [projectName, setProjectName] = useState("");
  const [defaultProjectList] = useState(projectList);
  const [{ connectedWalletAddress }] = useStateValue();

  const setSelectedProject = async function(project: IProject) {
    dispatch({
      type: "SET_SELECTED_PROJECT",
      selectedProject: project
    });
  }

  useEffect(()=>{
    const refreshProjectList = async function() {
      const pList = await enabledProjects();
      dispatch({
        type: "ADD_PROJECT_TO_LIST",
        projectList: pList,
      });
      if(selectedProject === null && pList.length > 0) {
        await setSelectedProject(pList[0]);
      }
      updateProjectSymbol(selectedProject, dispatch);
      if(connectedWalletAddress.length > 0)
        updateProjectTokenBalance(connectedWalletAddress, selectedProject, dispatch);
    }

    const countInterval = setInterval(refreshProjectList, 1000);

    return () => { clearInterval(countInterval); }
  });

  if (!projectList) {
    return null;
  }

  const handleSelectProject = (project: IProject) => {
    setSelectedProject(project);
    dispatch({
      type: "SET_MOBILE_MENU_VISIBILITY",
      showMobileMenu: !showMobileMenu,
    });
  };

  const handleProjectFilterChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setProjectName(event.target.value);
    const filter = event.target.value;
    const filteredProjects = projectList.filter((project: any) => {
      return project.name.toLowerCase().includes(filter.toLowerCase());
    });
    if (filter === "") {
      dispatch({
        type: "ADD_PROJECT_TO_LIST",
        projectList: defaultProjectList,
      });
    } else {
      dispatch({
        type: "ADD_PROJECT_TO_LIST",
        projectList: filteredProjects,
      });
    }
  };

  return (
    <>
      <div className="grid grid-cols-1 divide-y">
        <div className="side-nav-item rounded-tr-2xl font-semibold text-lg">
          Project List
        </div>
        <div className="flex gap-2 side-nav-item font-extralight h-12">
          <AiOutlineSearch />
          <input
            className="block w-4/5 px-4 py-2 text-white bg-primary  rounded-md focus:border-none focus:outline-none focus:ring"
            onChange={handleProjectFilterChange}
            type="text"
            placeholder="Search"
            name="name"
            value={projectName}
          />
        </div>
        {projectList.map((project: IProject) => (
          <div
            key={project.id}
            className={clsx({
              "active-side-nav-item font-extralight hover:bg-secondary":
              selectedProject !== null && (selectedProject.id === project.id),
              "side-nav-item font-extralight cursor-pointer":
              selectedProject === null || selectedProject.id !== project.id,
            })}
            onClick={() => handleSelectProject(project)}
          >
            {project.name}
          </div>
        ))}

        <div className="side-nav-item font-extralight h-12"></div>
        <div
          className="side-nav-item rounded-br-2xl hover:bg-secondary cursor-pointer font-extralight"
          onClick={() => setModalIsOpen(true)}
        >
          Get Listed!
        </div>
      </div>
      <GetListedModal
        modalIsOpen={modalIsOpen}
        setModalIsOpen={setModalIsOpen}
      />
    </>
  );
}
