import { useState } from "react";
import ConnectWalletModal from "../Header/ConnectWalletModal";
import { useStateValue } from "src/context/StateProvider";
import { getUserFriendlyAddress, startRefreshWalletAddress } from "src/context/helpers";

export default function FooterMenu() {
  const [modalIsOpen, setModalIsOpen] = useState<boolean>(false);
  const [ { connectedWalletAddress, selectedProject,
           currentProjectTokenSymbol , currentProjectTokenBalance}, dispatch] = useStateValue();


  return (
    <>
      <div className="grid grid-cols-2 bg-white border-t-[1px]">
        <div className="flex flex-col items-center justify-center text-sm border-x-2 border-x-gray-200 py-4 px-8 text-gray-500 hover:text-black cursor-pointer">
          <p>${ currentProjectTokenSymbol } Balance: { currentProjectTokenBalance }</p>
        </div>

        <div
          className="flex flex-col items-center justify-center text-sm border-x-2 border-x-gray-200 py-4 px-8 text-gray-500 hover:text-black cursor-pointer"
          onClick={() => startRefreshWalletAddress(dispatch) }
        >
          <p>
          { getUserFriendlyAddress(connectedWalletAddress) }
          </p>
        </div>
      </div>
      <ConnectWalletModal
        modalIsOpen={modalIsOpen}
        setModalIsOpen={setModalIsOpen}
      />
    </>
  );
}
