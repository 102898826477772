import React, { useEffect } from "react";
import { useStateValue } from "src/context/StateProvider";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { getUSDTAllowance, approveUSDTPayment, addProject,
        isValidAddress, getProjectListingFee, tokenExistsOnPolygon } from "src/ethereum/contracts";
import { handleContractCall, showError, startRefreshWalletAddress } from "src/context/helpers";

toast.configure();

export default function GetListedForm({ setModalIsOpen }: any) {
  const [{ selectedProject, connectedWalletAddress,
          currentUSDTAllowance, usdtDecimals }, dispatch] = useStateValue();
  const [projectName, setProjectName] = React.useState("");
  const [tokenAddress, setTokenAddress] = React.useState("");
  const [projectListingFee, setProjectListingFee] = React.useState(null);

  const fetchUSDTAllowance = async () => {
    var allowance = await getUSDTAllowance(connectedWalletAddress);
    await dispatch({
      type: "SET_CURRENT_USDT_ALLOWANCE",
      currentUSDTAllowance: Number(allowance),
    });
  }

  const refreshProjectListingFee = async () => {
    setProjectListingFee(await getProjectListingFee());
  }

  useEffect(() => {
    if(!currentUSDTAllowance)
      fetchUSDTAllowance();
    if(projectListingFee == null)
      refreshProjectListingFee();
      
    const interval = setInterval(() =>{
      fetchUSDTAllowance();
      refreshProjectListingFee();
    }, 2000);
    return () => clearInterval(interval); // This represents the unmount function, in which you need to clear your interval to prevent memory leaks.
  }, []);

  const canAffordProjectListing = () => {
    return projectListingFee && currentUSDTAllowance >= projectListingFee;
  }

  const getHumanReadableProjectListingFee = () => {
    if(projectListingFee)
      return projectListingFee / 10**usdtDecimals;
  }

  const handleApproveProjectListingFee = async () => {
    await approveUSDTPayment(projectListingFee);
  }

  const handleProjectNameChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setProjectName(event.target.value);
  };

  const handleTokenAddressChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setTokenAddress(event.target.value);
  };

  
  const handleSubmit = async () => {
    if (projectName === "" || tokenAddress === "") {
      showError("Please fill all fields");
      return;
    }
    if(!isValidAddress(tokenAddress)) {
      showError("invalid token Address");
      return;
    }
    const tokenExists = await tokenExistsOnPolygon(tokenAddress);
    if(!tokenExists) {
      showError("Could not verify the token on Polygon Mainnet. Make sure you provided the correct token address");
      return;
    }

    handleContractCall(addProject(projectName, tokenAddress));
    
    setModalIsOpen(false);
  };

  return (
    <>
      {
        connectedWalletAddress == "" && (
        <div>
          <div className="mt-4">
              <button className="black-button w-full" onClick={() => {
                startRefreshWalletAddress(dispatch);
              }}>
                Connect Wallet
              </button>
            </div>
        </div>
       )
     }
     {
       connectedWalletAddress != "" && canAffordProjectListing() == false && (
        <div>
          <div className="mt-4">
              <button className="black-button w-full" onClick={handleApproveProjectListingFee}>
                Approve { getHumanReadableProjectListingFee() }USDT Project Listing Fee
              </button>
            </div>
        </div>
       )
     }
     { connectedWalletAddress != "" && canAffordProjectListing() && (
       <div>
          <div>
              <label className="block mb-2 text-primary text-sm font-medium">
                Project Name
              </label>
              <input
                className="block w-full px-4 py-2 text-gray-700 bg-white border rounded-md focus:border-blue-500 focus:outline-none focus:ring"
                onChange={handleProjectNameChange}
                type="text"
                placeholder="Project Name"
                name="name"
                value={projectName}
              />
            </div>
            <div className="mt-4">
              <label className="block mb-2 text-primary text-sm font-medium">
                Token Address
              </label>
              <input
                className="block w-full px-4 py-2 text-gray-700 bg-white border rounded-md focus:border-blue-500 focus:outline-none focus:ring"
                onChange={handleTokenAddressChange}
                type="text"
                placeholder="Token Address"
                name="address"
                value={tokenAddress}
              />
            </div>
            <div className="mt-4">
              <button className="black-button w-full" onClick={handleSubmit}>
                Submit
              </button>
            </div>
     </div>
     )}
    </>
  );
}
