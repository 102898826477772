import React, { useState, useEffect } from "react";
import { MdAddCircleOutline } from "react-icons/md";
import { useStateValue } from "src/context/StateProvider";
import { IWhitelist } from "src/interfaces/projects";
import ProjectCard from "./ProjectCard";
import AddWhitelistModal from "./AddWhitelistModal";

export default function Whitelists( { currentWhitelists }: { currentWhitelists: IWhitelist[] }) {
  const [modalIsOpen, setModalIsOpen] = useState<boolean>(false);
  const [{ selectedProject, connectedWalletAddress }] = useStateValue();

  const handleAddNewItem = () => {
    if (connectedWalletAddress !== selectedProject.owner) {
      return;
    }
    setModalIsOpen(true);
  };

  return selectedProject && currentWhitelists && (
    <>
      <div className="flex gap-10 flex-wrap">
        {connectedWalletAddress === selectedProject.owner && (
          <div
            className="w-[214px] h-[225px] bg-primary hover:bg-slate-800 rounded-2xl flex justify-center items-center cursor-pointer"
            onClick={handleAddNewItem}
          >
            <MdAddCircleOutline className="text-slate-500" size={100} />
          </div>
        )}
        {currentWhitelists.map((whitelist) => (
          <ProjectCard
            key={whitelist.id}
            id={whitelist.id}
            name={whitelist.name}
            cost={whitelist.cost}
            userFriendlyCost={whitelist.userFriendlyCost}
            imgURL={whitelist.imgURL}
            discordURL={whitelist.discordURL}
            size={whitelist.size}
            maxSize={whitelist.maxSize}
            addresses={whitelist.addresses}
            discordIds={whitelist.discordIds}
            project={whitelist.project}
          />
        ))}
      </div>
      <AddWhitelistModal
        modalIsOpen={modalIsOpen}
        setModalIsOpen={setModalIsOpen}
      />
    </>
  );
}
