import { connectWallet, getHumanReadableTokenBalance, getTokenSymbol } from "src/ethereum/contracts"
import { IProject } from "src/interfaces/projects";
import { toast } from "react-toastify";

export async function startRefreshWalletAddress(dispatch: any) {
   const refreshMenu = async() => {
    const address = await connectWallet() || "";
    refreshWalletAddress(address, dispatch);
   } 
  const countInterval = setInterval(refreshMenu, 3000);

  return () => { clearInterval(countInterval) }
}
  
export const getUserFriendlyAddress = function(walletAddress: string) {
  const walletLength = walletAddress.length
  if(walletLength > 0)
    return walletAddress.substring(0, 5) + " ... " + walletAddress.substring(walletLength - 4, walletLength)
  return "Connect Wallet"
}

const refreshWalletAddress = async (walletAddress: string, dispatch: any) => { 
  dispatch({
    type: "CONNECT_WALLET",
    connectedWalletAddress: walletAddress
  });
}

export const updateProjectSymbol = async function(project: IProject, dispatch: any) {
  getTokenSymbol(project).then((tokenSymbol) =>{
    dispatch({
      type: "SET_SELECTED_PROJECT_TOKEN_SYMBOL",
      currentProjectTokenSymbol: tokenSymbol,
    });
  }).catch((error)=>{
    dispatch({
      type: "SET_SELECTED_PROJECT_TOKEN_SYMBOL",
      currentProjectTokenSymbol: "",
    });
  });
}

export const updateProjectTokenBalance = async function(walletAddress: string, project: IProject, dispatch: any) {
  getHumanReadableTokenBalance(walletAddress, project).then((tokenBalance) =>{
    dispatch({
      type: "SET_SELECTED_PROJECT_TOKEN_BALANCE",
      currentProjectTokenBalance: tokenBalance,
    });
  }).catch((error)=>{
    dispatch({
      type: "SET_SELECTED_PROJECT_TOKEN_BALANCE",
      currentProjectTokenBalance: "",
    });
  });
}

export const showError = function(errorMsg: any) {
  toast.error(errorMsg, {
    position: "bottom-right",
    autoClose: false,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "dark",
  });
}

export const showInfo = function(msg: any) {
  toast.info(msg, {
    position: "bottom-right",
    autoClose: false,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "dark",
  });
}

export const handleContractCall = async (promise: any) => {
  return promise.then((tranx: any)=>{
    if(tranx) {
      const trxHash = tranx.hash;
      const trxUrl = `${process.env.REACT_APP_NETWORK_BASE_URI}${trxHash}`;
      // <Watch ariaLabel="loading-indicator" />
      const msg = <div>View on etherscan <a href={trxUrl} target="_blank" rel="noreferrer">here</a></div>;
      showInfo(msg);
      return tranx;
    }
  }).catch((err: any)=>{
    console.log(`error: ${JSON.stringify(err)}`);
    let errorMsg = <p>error occurred completing the request</p>;
    if(err["error"]) {
      errorMsg = <p>{err["error"]["message"]}</p>;
    }
    
    showError(errorMsg);
    throw err;
  });
}
