import React, { useState, useEffect, useCallback } from "react";
import { create } from 'ipfs-http-client';
import clsx from "clsx";
import wait from "wait";
import "src/assets/styles/modal.css";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useStateValue } from "src/context/StateProvider";
import { addWhitelist, isTransactionMined } from "src/ethereum/contracts";
import { FilePond, registerPlugin } from "react-filepond";
import { buyWhitelistSpot, approveToken, getTokenAllowance, getTokenBalance } from "src/ethereum/contracts";
import { handleContractCall, showError } from "src/context/helpers";


toast.configure();

export default function BuyWhitelistForm({ setModalIsOpen, walletAddress, project, whitelistId, whitelistCost }) {
  const [submitDisabled, setSubmitDisabled] = useState(true);
  const [discordId, setDiscordId] = useState();
  const [btnText, setBtnText] = useState("Waiting ...");

  const hasTokenAllowance = async ()=>{
    const tokenAllowance = await getTokenAllowance(walletAddress, project.tokenAddress);
    return tokenAllowance >= whitelistCost;
  }

  const hasRequiredBalance = async () =>{
    const tokenBalance = await getTokenBalance(walletAddress, project);
    return tokenBalance > whitelistCost;
  }

  const approveAndSubscribe = async () => {
    const hasAllowance = await hasTokenAllowance();
    if(!hasAllowance)
      await handleContractCall(approveToken(project.tokenAddress, whitelistCost));
    setTimeout(async ()=>{
      await handleContractCall(buyWhitelistSpot(project, whitelistId, discordId));
    }, 1000);
  }

  const handleSubscribe = async () => {
    if(await hasRequiredBalance()) {
      handleContractCall(approveAndSubscribe());
    }
    else
      showError(`Looks like you don't have enough token balance to make the purchase!`);
  };

  const handleDiscordIdChange = (event) => {
    setDiscordId(event.target.value);
  }

  const handleSubmit = async () => {
    handleSubscribe();
    setModalIsOpen(false);
  };

  const checkSubmitStatus = useCallback(() => {
    if (`${discordId}`.length == 18) {
      setSubmitDisabled(false);
      setBtnText("Purchase");
    } else {
      setSubmitDisabled(true);
    }
  }, [discordId]);

  useEffect(() => {
    checkSubmitStatus();
  }, [discordId, checkSubmitStatus]);

  return (
    <>
      {/* Page Main Content */}
      <div className="md:bg-pale-purple-bg">
        <div className="container md:grid md:place-items-center md:h-full ">
          {/* White Card */}
          <div className="bg-primary md:p-6 pt-6 rounded-2xl md:shadow-md w-auto md:w-[22rem]">
            {/* Text Inputs */}
            <div>
              <label className="block mb-2 text-primary text-sm font-medium">
                Enter Discord Id
              </label>
              <input
                className="block w-full px-4 py-2 text-gray-700 bg-white border rounded-md focus:border-blue-500 focus:outline-none focus:ring"
                onChange={handleDiscordIdChange}
                type="number"
                placeholder="Enter your 18 digit Discord Id"
                name="discordId"
                value={discordId}
              />
            </div>

            {/* Submit Button */}
            <div className=" flex justify-around mt-6 pb-2">
              <button
                type="button"
                onClick={handleSubmit}
                disabled={submitDisabled}
                className={clsx(
                  {
                    "bg-blue-400 items-center gap-2 w-full justify-center flex font-semibold hover:bg-secondary-light hover:shadow-xl text-white rounded-xl pl-6 pr-4 py-2 z-10 cursor-pointer":
                      !submitDisabled,
                  },
                  {
                    "bg-[#cdc4d7] items-center gap-2 w-full justify-center flex font-semibold text-white rounded-xl pl-6 pr-4 py-2 z-10":
                      submitDisabled,
                  }
                )}
              >
                {btnText}
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
