import React from "react";
import { useStateValue } from "src/context/StateProvider";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

toast.configure();

export default function ConnectWalletForm({ setModalIsOpen }: any) {
  const [{ projectList }, dispatch] = useStateValue();
  const [walletAddress, SetWalletAddress] = React.useState("");

  const handleWalletAddressChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    SetWalletAddress(event.target.value);
  };

  const handleSubmit = async () => {
    const foundProject = projectList.find((project: any) => {
      return project.owner === walletAddress;
    });

    if (!foundProject) {
      toast.error("Invalid Wallet Address", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
      return;
    }

    await dispatch({
      type: "CONNECT_WALLET",
      connectedWalletAddress: walletAddress,
    });
    toast.success("Wallet Connected", {
      position: "bottom-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });
    setModalIsOpen(false);
  };

  return (
    <>
      <div>
        <label className="block mb-2 text-primary text-sm font-medium">
          Enter Wallet Address
        </label>
        <input
          className="block w-full px-4 py-2 text-gray-700 bg-white border rounded-md focus:border-blue-500 focus:outline-none focus:ring"
          onChange={handleWalletAddressChange}
          type="text"
          placeholder="Wallet Address"
          name="name"
          value={walletAddress}
        />
      </div>
      <div className="mt-4">
        <button className="black-button w-full" onClick={handleSubmit}>
          Connect
        </button>
      </div>
    </>
  );
}
