import { useEffect, useState } from "react";
import PaginatedItems from "src/components/PaginatedItems";
import { useStateValue } from "src/context/StateProvider";
import { getWhitelists } from "src/ethereum/contracts";

export default function Home() {
  const [ { selectedProject, currentWhitelists }, dispatch, ] = useStateValue();

  useEffect(()=>{
    const refreshCurrentWhitelists = async function() {
      if(selectedProject === undefined)
        return;
      var whistlists = await getWhitelists(selectedProject);
      dispatch({
        type: "SET_CURRENT_WHITELISTS",
        currentWhitelists: whistlists
      });
    }

    const countInterval = setInterval(refreshCurrentWhitelists, 1000);

    return () => { clearInterval(countInterval) }
  });

  return (
    <>
      <div>
        <PaginatedItems whitelists={currentWhitelists} itemsPerPage={10} />
      </div>
    </>
  );
}
