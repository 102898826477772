import { ethers, BigNumber } from "ethers";
import { marketPlaceAbi, tokenAbi } from "./abi";

const getProvider = function() {
    return new ethers.providers.Web3Provider(window.ethereum, "any");
}

export const connectWallet = async function() {
    const { ethereum } = window;
    if (ethereum) {
        const provider = getProvider();
        // connect address
        await provider.send("eth_requestAccounts", []);
        const accounts = await provider.listAccounts();
        return accounts[0];
    }
}

const getContract = async function (contractAddress, withSigner, abi) {
    const { ethereum } = window;
    if (ethereum) {
        const provider = getProvider();
        const contract = new ethers.Contract(contractAddress, abi, provider);
        if(withSigner) {
            const signer = provider.getSigner();
            return contract.connect(signer);
        }
        return contract;
    }
    else {
        // meta mask is not installed
        //  setErrorMessage("Looks MetaMask is not installed on your browser");
    }
};

export const marketplaceContract = async function (readonly) {
    return await getContract(process.env.REACT_APP_MARKETPLACE_CONTRACT, !readonly, marketPlaceAbi);
};

export const usdtContract = async function () {
    return await getContract(process.env.REACT_APP_USDT_ADDRESS, true, tokenAbi);
};

const buildProject = function(projectData) {
    return {
        id: Number(projectData[0]),
        name: projectData[1],
        shortDesc: projectData[2],
        owner: projectData[3],
        tokenAddress: projectData[4],
        imgURL: projectData[5],
        disabled: projectData[6],
        verified: projectData[7],
    };
}

export const enabledProjects = async function() {
    const contract = await marketplaceContract(true);
    var projects = await contract.getProjects();
    projects = projects.map(function(projectData) {
        return buildProject(projectData);
    });
    return projects.filter(project => project.disabled == false);
}

export const tokenExistsOnPolygon = async function(tokenAddress) {
    return await getTokenDecimals(tokenAddress).then((_)=>{
        return true;
    }).catch((_)=>{
        return false;
    })
}

export const addProject = async function(name, tokenAddress) {
    const contract = await marketplaceContract(false);
    return await contract.addProject(name, tokenAddress, "", "");  
}

export const getTokenDecimals = async function(tokenAddress) {
    const contract = await getContract(tokenAddress, true, tokenAbi);
    return await contract.decimals();
}

export const approveUSDTPayment = async function(amount) {
    return await approveToken(process.env.REACT_APP_USDT_ADDRESS, `${amount}`);
}

export const approveToken = async function(tokenAddress, amount) {
    const contract = await getContract(tokenAddress, true, tokenAbi);
    return await contract.approve(process.env.REACT_APP_MARKETPLACE_CONTRACT, `${amount}`);
}

export const getTokenAllowance = async function(walletAddress, tokenAddress) {
    const contract = await getContract(tokenAddress, true, tokenAbi);
    return await contract.allowance(walletAddress, process.env.REACT_APP_MARKETPLACE_CONTRACT);
}

export const getUSDTAllowance = async function(walletAddress) {
    return await getTokenAllowance(walletAddress, process.env.REACT_APP_USDT_ADDRESS) ;
}

const getProjectDecimals = async function(project) {
    const tokenExists = await tokenExistsOnPolygon(project.tokenAddress);
    let decimals = 1;
    if(tokenExists)
        decimals = await getTokenDecimals(project.tokenAddress);
    return decimals;
}

export const addWhitelist = async function(project, name, description, cost, maxSize, imageURL, discordURL) {
    const decimals = await getProjectDecimals(project);
    const costInDecimals = BigNumber.from(cost).mul(BigNumber.from(10).pow(decimals));
    const contract = await marketplaceContract(false);
    return await contract.addWhitelist(project.id, name, description, costInDecimals, maxSize, imageURL, discordURL);
}

export const buyWhitelistSpot = async function(project, whitelistId, discordId) {
    const contract = await marketplaceContract(false);
    return await contract.buyWhitelistSpot(project.id, whitelistId, discordId);
}

export const getWhitelists = async function(project) {
    const decimals = await getProjectDecimals(project);
    const contract = await marketplaceContract(true);
    var whitelists = await contract.getWhitelists(project.id);
    return whitelists.map(function(whitelistData) {
        return buildWhitelist(project, whitelistData, decimals);
    });
}

export const getWhitelistCount = async function(project) {
    const contract = await marketplaceContract(true);
    return await contract.whitelistCount(project.id);
}

const buildWhitelist = function(project, whitelistData, decimals) {
    var cost = Number(whitelistData[5]);
    var costInTokenUnits = cost / (10**decimals);
    return {
        id: Number(whitelistData[0]),
        name: whitelistData[1],
        shortDesc: whitelistData[2],
        imgURL: whitelistData[3],
        discordURL: whitelistData[4],
        cost: cost,
        userFriendlyCost: costInTokenUnits,
        maxSize: whitelistData[6],
        addresses: whitelistData[7],
        discordIds: whitelistData[8],
        paused: whitelistData[9],
        size: whitelistData[7].length,
        project: project
    };
}


export const getWhitelist = async function(project, whitelistId) {
    const decimals = await getProjectDecimals(project);
    const contract = await marketplaceContract(true);
    var whitelistData = contract.getWhitelist(project.id, whitelistId);
    return buildWhitelist(project, whitelistData, decimals);
}

const projectToken = async function(project, readonly) {
    return await getContract(project.tokenAddress, !readonly, tokenAbi);
}

export const getTokenBalance = async function(connectedAddress, project) {
    const contract = await projectToken(project, true);
    return await contract.balanceOf(connectedAddress);
}

export const getHumanReadableTokenBalance = async function(connectedAddress, project) {
    const contract = await projectToken(project, true);
    const decimals = await contract.decimals();
    const balance = await contract.balanceOf(connectedAddress);
    return (balance / (10**decimals)).toFixed(4);
}

export const getTokenSymbol = async function(project) {
    const contract = await projectToken(project, true);
    return await contract.symbol();
}

export const isTransactionMined = async (transactionHash) => {
    const provider = getProvider();
    const txReceipt = provider.getTransactionReceipt(transactionHash);
    console.log("successfully completed: " + JSON.stringify(txReceipt));
    if (txReceipt && txReceipt.blockNumber) {
        console.log("successfully completed: " + txReceipt.blockNumber);
        return txReceipt;
    }
}

export const getProjectListingFee = async function() {
    const contract = await marketplaceContract(true);
    return await contract.projectListingFee()
}

export const isValidAddress = function(address) {
    return ethers.utils.isAddress(address);
}