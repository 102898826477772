import { useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import { IWhitelist } from "src/interfaces/projects";
import Whitelists from "./Whitelists";

type PaginatedItemsProps = {
  itemsPerPage: number;
  whitelists: IWhitelist[];
};

export default function PaginatedItems({
  itemsPerPage,
  whitelists,
}: PaginatedItemsProps) {
  // We start with an empty list of items.
  const [currentItems, setCurrentItems] = useState<IWhitelist[]>(whitelists);
  const [pageCount, setPageCount] = useState(0);
  // Here we use item offsets; we could also use page offsets
  // following the API or data you're working with.
  const [itemOffset, setItemOffset] = useState(0);

  useEffect(() => {
    // Fetch items from another resources.
    const endOffset = itemOffset + itemsPerPage;
    setCurrentItems(whitelists?.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(whitelists.length / itemsPerPage));
  }, [itemOffset, itemsPerPage, whitelists]);

  // Invoke when user click to request another page.
  const handlePageClick = (event: { selected: number }) => {
    const newOffset = (event.selected * itemsPerPage) % whitelists.length;
    setItemOffset(newOffset);
  };

  return (
    <div className="ml-12 ">
      <Whitelists currentWhitelists={currentItems} />

      <div className="mt-14">
        <ReactPaginate
          breakLabel="..."
          nextLabel="next >"
          onPageChange={handlePageClick}
          containerClassName="flex gap-5"
          activeClassName="text-slate-500"
          pageRangeDisplayed={5}
          pageCount={pageCount}
          previousLabel="< previous"
          // @ts-ignore
          renderOnZeroPageCount={null}
        />
      </div>
    </div>
  );
}
