export const initialState = {
  connectedWalletAddress: "",
  showMobileMenu: false,
  selectedProject: null,
  projectList: [],
  currentWhitelists: [],
  currentProjectTokenBalance: "",
  currentProjectTokenSymbol: "",
  currentUSDTAllowance: 0,
  usdtDecimals: 6,
};

const reducer = (state, action) => {
  switch (action.type) {
    case "CONNECT_WALLET":
      return {
        ...state,
        connectedWalletAddress: action.connectedWalletAddress,
      };
    case "ADD_PROJECT_TO_LIST":
      return {
        ...state,
        projectList: action.projectList,
      };
    case "SET_CURRENT_WHITELISTS":
      return {
        ...state,
        currentWhitelists: action.currentWhitelists,
      };
    case "SET_SELECTED_PROJECT":
      return {
        ...state,
        selectedProject: action.selectedProject
      };
    case "SET_SELECTED_PROJECT_TOKEN_SYMBOL":
      return {
        ...state,
        currentProjectTokenSymbol: action.currentProjectTokenSymbol
      };
    case "SET_SELECTED_PROJECT_TOKEN_BALANCE":
      return {
        ...state,
        currentProjectTokenBalance: action.currentProjectTokenBalance
      };
    case "SET_SELECTED_PROJECT_WALLET_ADDRESS":
      return {
        ...state,
        selectedProject: action.selectedProject,
      };
    case "SET_MOBILE_MENU_VISIBILITY":
      return {
        ...state,
        showMobileMenu: action.showMobileMenu,
      };
    case "SET_CURRENT_USDT_ALLOWANCE":
      return {
        ...state,
        currentUSDTAllowance: action.currentUSDTAllowance,
      };
    default:
      return state;
  }
};

export default reducer;
