import FooterMenu from "./FooterMenu";
import Header from "./Header";
import SideNav from "./SideNav";

export default function Layout({ children }: any) {
  return (
    <>
      <div className="sticky top-0 z-50">
        <Header />
      </div>
      <div>
        <SideNav />
      </div>
      <div className="lg:ml-64 py-10 px-6 flex-grow">{children}</div>
      <div className="sticky bottom-0 lg:hidden">
        <FooterMenu />
      </div>
    </>
  );
}
