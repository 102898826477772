import React, { useState } from "react";
import clsx from "clsx";
import ConnectWalletModal from "./ConnectWalletModal";
import { useStateValue } from "src/context/StateProvider";
import { MdOutlineClose, MdOutlineMenu } from "react-icons/md";
import Menu from "../SideNav/Menu";
import FadeInLeft from "src/components/MotionDivs/FadeInLeft";
import { getUserFriendlyAddress, startRefreshWalletAddress } from "src/context/helpers";

export default function Header() {
  const [modalIsOpen, setModalIsOpen] = useState<boolean>(false);
  const [{ showMobileMenu, currentProjectTokenBalance,
           currentProjectTokenSymbol, connectedWalletAddress, selectedProject }, dispatch] = useStateValue();     

  const toggleMenu = () => {
    dispatch({
      type: "SET_MOBILE_MENU_VISIBILITY",
      showMobileMenu: !showMobileMenu,
    });
  };

  return (
    <>
      <header className="top-0 z-50 py-5 relative border-b-2 bg-secondary">
        {/* Top Bar */}
        <div className="flex justify-between px-6">
          {/* left */}
          <div>
            <div
              className="lg:flex items-center h-10 
        cursor-pointer hidden"
            >
              <img src="/images/logo.png" alt="" width={152} height={120} />
            </div>
            <div
              className="relative flex items-center h-10  
        cursor-pointer my-auto lg:hidden"
            >
              <img src="/images/logo.png" alt="" width={152} height={120} />
            </div>
          </div>

          {/* right */}
          {/* Logged In Profile  */}
          <div className="hidden lg:flex justify-between items-center w-4/5 pl-16">
            <div className="flex gap-12">
              <a href="#">
                <p className="hover:text-slate-700 cursor-pointer">
                  Marketplace
                </p>
              </a>
              <a href="https://frenzforever.io/" target="_blank">
                <p className="hover:text-slate-700 cursor-pointer">
                  Staking Hub
                </p>
              </a>
            </div>
            <div className="lg:flex items-center space-x-6 hidden">
              <button type="button" className="black-button">
                { currentProjectTokenSymbol } Balance: { currentProjectTokenBalance }
              </button>
              <button
                type="button"
                className="black-button"
                onClick={() => startRefreshWalletAddress(dispatch) }
              >
                { getUserFriendlyAddress(connectedWalletAddress) }
              </button>
            </div>
          </div>
          {/* Burger Icon */}
          <div className="flex lg:hidden">
            <div
              className={clsx({
                "block cursor-pointer": !showMobileMenu,
                hidden: showMobileMenu,
              })}
              onClick={toggleMenu}
            >
              <MdOutlineMenu size={30} />
            </div>
            <div
              className={clsx({
                "block cursor-pointer": showMobileMenu,
                hidden: !showMobileMenu,
              })}
              onClick={toggleMenu}
            >
              <MdOutlineClose size={30} />
            </div>
          </div>
        </div>
        {/* Mobile Menu */}
        <div
          className={clsx({
            block: showMobileMenu,
            hidden: !showMobileMenu,
          })}
        >
          <div className="w-full px-6 bg-pale-purple-bg absolute lg:hidden h-screen">
            <div className="mt-5">
              <FadeInLeft showComponent={showMobileMenu}>
                <Menu />
              </FadeInLeft>
            </div>
          </div>
        </div>
      </header>
      <ConnectWalletModal
        modalIsOpen={modalIsOpen}
        setModalIsOpen={setModalIsOpen}
      />
    </>
  );
}
